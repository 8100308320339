<template>
  <div class="Table mt-8 mx-2">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="inquiryId"
      :sort-by="['close','created']"
      :sort-desc="[false,true]"
      class="elevation-0"
      :search="search"
      :loading="loading"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >

      <!-- カスタムフッター -->
      <template v-slot:footer  >

          <v-row class="text-center pt-2 align-center mx-2 mt-0" width="90%" wrap style="border-top:1px solid #bbbbbb;">
              <v-col class="text-truncate opacity-07 body-2 mb-0 pa-0" cols="12" md="12">
                  {{ pageText }} 
              </v-col>
              <v-col cols="12" md="12" class="mb-3 mt-0">
                  <v-pagination
                      v-model="page"
                      color="narekomu"
                      class="mt-0"
                      :length="pageCount">
                  </v-pagination>
              </v-col>
              </v-row>
      </template>

      <template v-slot:top>
        <v-toolbar flat>  
          <v-container>
            <v-row>
              <v-col class="ma-0 pa-0" cols=5>
                <v-btn
                  color="#4ECDC4"
                  dark
                  class="mb-2 ml-0 mr-1 pa-2"
                  @click="dialog=true"
                >
                新規お問合せ
                </v-btn>
              </v-col>

        

              <v-spacer></v-spacer>
              <v-col  class="ma-0 pa-0" cols=6>
                <v-text-field
                  v-model="search"
                  :append-icon="icons.magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-toolbar>
      </template>
    
      <template v-if="!$vuetify.breakpoint.xs" v-slot:body="{ items: des }">
         <v-progress-linear v-if="loading"
          indeterminate
          color="narekomu"
        ></v-progress-linear>
        <tbody>
          <tr v-for="d in des" :key="d.inquiryId" @click="showDetail(d)" >
            <!-- <td>{{ d.inquiryId }}</td> -->
            <td>{{ makeTextShort(d.name) }}</td>
            <td v-if="!$vuetify.breakpoint.smAndDown" >{{ makeTextShort(d.email) }}</td>
            <td>{{ d.type }}</td>
            <td>{{ makeTextShort(d.details) }}</td>
            <td>{{ d.created }}</td>
            <td>
              <v-chip v-if="d.close"
                class="ma-2"
                color="green"
                text-color="white"
              >解決</v-chip>
              <v-chip v-else
                class="ma-2"
                color="red lighten-1"
                text-color="white"
              >未解決</v-chip>
            </td>
          </tr>
        </tbody>
      </template>
        <!-- スマホ用 -->
      <template v-else v-slot:body="{ items: des }">
        <v-progress-linear v-if="loading"
          indeterminate
          color="narekomu"
        ></v-progress-linear>
        <tbody>
          <tr v-for="d in des" :key="d.inquiryId" @click="showDetail(d)" class="d-flex flex-wrap pa-2"  style="border-bottom:0.1px solid #bbbbbb ; " >
            <p class="font-weight-bold text-body-2" style="width:60%;" >{{ d.type }}</p>
            <p class="text--secondary text-caption mt-auto text-end"   style="width:40%;">{{d.created}}</p>
            <p class="ma-0 text-body-2"  style="width:100%;"><span class="text-caption ">名前<span class="white--text">＿</span> :</span> {{d.name}}</p>
            <p class="ma-0 text-body-2"  style="width:100%;"><span class="text-caption ">メール :</span> {{d.email}}</p>
            <p class="ma-0 text-body-2"  style="width:100%;"><span class="text-caption ">状態<span class="white--text">＿</span> :</span> 
              <v-chip v-if="d.close"
                class="ma-2"
                color="green"
                text-color="white"
              >解決</v-chip>
              <v-chip v-else
                class="ma-2"
                color="red lighten-1"
                text-color="white"
              >未解決</v-chip>
            </p>

          </tr>
        </tbody>
      </template>



    </v-data-table>
      <v-dialog v-model="dialog" max-width="700px" loading="false" persistent :max-height="$vuetify.breakpoint.xs?'100%':'400'" :fullscreen="$vuetify.breakpoint.xs" style="z-index:2100;" >
          <Inquiry
            v-on:closeEmit="close"
            v-on:createInquiryEmit="createInquiry"
          />
        </v-dialog>     
    
          <v-dialog v-model="dialogDetail" max-width="700px" :fullscreen="$vuetify.breakpoint.xs" loading="false" persistent style="z-index:2000;">
          <DetailInquiry
            :detailItem="detailItem"
            v-on:closeEmit="close"
            v-on:changeStatusEmit="changeStatus"
          />
        </v-dialog>   

  </div>
</template> 

<script>
import axios from "axios"
import { decodeTime  } from "ulidx";
import { mdiMagnify } from '@mdi/js';

const Inquiry = () => import('@/components/Inquiry.vue');
const DetailInquiry = () => import('@/components/DetailInquiry.vue');

export default {
  components: { Inquiry , DetailInquiry},
  name: 'TableInquiry',
  data () {
    return {
    icons:{"magnify":mdiMagnify},
    loading:false,
    dialog:false,
    dialogDetail:false,
    detailItem:null,
    search:null,
    items:[],
    itemsPerPage:10,
    page: 1,
    inquiryType:["アカウントや請求について","データ容量の追加について","バグや不具合について","その他"],
    }
  },
  computed: {
    pageText(){
      return `${Math.max(1,(this.page-1)*this.itemsPerPage)} - ${Math.min(this.page*this.itemsPerPage,this.totalRecords)} of ${this.totalRecords} `
    },
    totalRecords() {
      return this.items.length
    },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage)
    },
    headers () {
      if(this.$vuetify.breakpoint.sm){
               return [
          {
            text: 'お名前',
            align: 'start',
            sortable: true,
            value: 'name',
            width: "10%",
          },
          {
            text: '種類',
            align: 'start',
            sortable: true,
            value: 'type',
            width: "15%",
          },
          {
            text: 'お問合せ内容',
            align: 'start',
            sortable: true,
            value: 'details',
            width: "15%",
          },
          {
            text: '投稿日',
            align: 'start',
            sortable: true,
            value: 'created',
            width: "15%",
          },
          {
            text: '状態',
            align: 'start',
            sortable: true,
            value: 'close',
            width: "10%",
          },       
        ]
      }
      else{
        return [
          {
            text: 'お名前',
            align: 'start',
            sortable: true,
            value: 'name',
            width: "10%",
          },
          {
            text: 'メールアドレス',
            align: 'start',
            sortable: true,
            value: 'email',
            width: "15%",
          },
          {
            text: '種類',
            align: 'start',
            sortable: true,
            value: 'type',
            width: "15%",
          },
          {
            text: 'お問合せ内容',
            align: 'start',
            sortable: true,
            value: 'details',
            width: "20%",
          },
          {
            text: '投稿日',
            align: 'start',
            sortable: true,
            value: 'created',
            width: "15%",
          },
          {
            text: '状態',
            align: 'start',
            sortable: true,
            value: 'close',
            width: "10%",
          },       
        ]
      }
    },
  },

  async created (){
    await this.getData();
    this.detailItem = {"name":"-",};
  },


  methods:{
    //お問合せ一覧の表示
    getData: async function () {
      this.loading = true;
      // console.log(this.$store.state)
      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/inquiries`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
      },
      ).then((response) => {
        this.items = response.data.results;
        for(let i = 0; i< this.items.length; i++){
          this.items[i].created = this.makeCreateTime(this.items[i].inquiryId)
          this.items[i].type = this.inquiryType[this.items[i].type];
        }
        this.loading = false;

      }).catch(reason => {
        console.log(reason)
      });
    },

    close (){
      this.dialog = false;
      this.dialogDetail = false;
    },

    //詳細の表示
    showDetail(item){
      this.detailItem = item;
      this.dialogDetail = true;
    },

    
    makeCreateTime(id){
      let time =new Date(decodeTime(id));
      return `${time.toLocaleDateString('ja-JP')} ${time.toLocaleTimeString('ja-JP')}`;
    },

    makeTextShort(text){
      if(text){
        let len = text.length;
        if(len > 15) return text.substr(0,14)+"...";
        return text
      }  
    },
    
    changeStatus(){
       let index = this.items.indexOf(this.detailItem);
       this.items[index].close = true;
       this.dialogDetail=false;
    },
    
    createInquiry(id,item){
      if(!item.email) item.email = this.$store.state.user_model.userData.userId;
      this.items.push({
        inquiryId:id,
        name:item.name,
        email: item.email,
        type:this.inquiryType[item.type],
        details:item.details,
        created:this.makeCreateTime(id),
        close:false
      })
    }
  },
  

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0 ;
  margin:auto;
  width:30%;
  text-align: center;

  height:110px;
}
li {
  margin: 0 10  ;
  width: 50%;
  text-align: left;
}
a {
  color: #42b983;
}

.text-start {
  padding: none;
}

v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}


</style>
